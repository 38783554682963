
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './App.css';
import DartFinishGenerator from './components/DartFinishGenerator';
import DartGame27 from './components/DartGame27';
import dartIconLg from './assets/dart-icon-lg.png';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/finishes" element={<DartFinishGenerator />} />
          <Route path="/twenty-seven" element={<DartGame27 />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export function HomePage() {
  return (
    <Container className="app-container">
      <Row>
        <Col>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <img src={dartIconLg} alt="Dart logo" width="100px"/>
              <h1>Welcome to Dart School</h1>
              <p>Select a game to play</p>
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-3">
            <Col md={3}>
              <Link to="/finishes"><Button className="w-100">Practice Finishes</Button></Link>
            </Col>
          </Row>
          <Row className="justify-content-md-center mt-3">
            <Col md={3}>
              <Link to="/twenty-seven"><Button className="w-100">Play Twenty Seven</Button></Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
