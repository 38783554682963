import React, { useState } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import "../App.css";
import recommendedFinishes from "../data/recommendedFinish.json";

function DartFinishGenerator() {
  const [finish, setFinish] = useState("");
  const [recentFinishes, setRecentFinishes] = useState([]); // Queue to store recent finishes
  const queueSize = 20; // Threshold for repeating numbers, easy to update
  const [finishType, setFinishType] = useState("");
  const [showRecommendedFinish, setShowRecommendedFinish] = useState(false);
  const [recommendedFinish, setRecommendedFinish] = useState(null);
	const [confirmExit, setConfirmExit] = useState(false);

  const bogeyNumbers = [169, 168, 166, 165, 163, 162, 159];
  const threeDartFinishes = [
    99, 102, 103, 105, 106, 108, 109, 111, 112, 113, 114, 115, 116, 117, 118,
    119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133,
    134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148,
    149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 160, 161, 164, 167, 170,
  ];
  const singleDartFinishes = [
    50,
    ...Array.from({ length: 20 }, (_, i) => 2 * (i + 1)),
  ]; // Every even number between 2 and 40, and 50 for bullseye
  const twoDartFinishes = Array.from({ length: 109 }, (_, i) => i + 2).filter(
    (f) =>
      !threeDartFinishes.includes(f) &&
      !singleDartFinishes.includes(f) &&
      !bogeyNumbers.includes(f)
  );

  const isValidFinish = (number) => {
    return !recentFinishes.includes(number);
  };

  const updateQueue = (newFinish) => {
    let updatedQueue = [...recentFinishes, newFinish];
    if (updatedQueue.length > queueSize) {
      updatedQueue.shift(); // Remove the oldest finish from the queue
    }
    setRecentFinishes(updatedQueue);
  };

  const generateFinish = (finishes, validator, type) => {
    let finish;
    do {
      finish = finishes[Math.floor(Math.random() * finishes.length)];
    } while (!validator(finish));
    setFinishType(type);

    // Find the recommended finish based on the generated score
    const recommendedFinish = recommendedFinishes.find(
      (item) => item.score === finish
    );
    console.log(recommendedFinishes, recommendedFinish, finish);

    if (recommendedFinish) {
      setFinish(finish);
      setRecommendedFinish(recommendedFinish);
    } else {
      // Handle the case where there's no recommended finish for the generated score
      setFinish(finish);
      setRecommendedFinish(null);
    }

    updateQueue(finish);
  };

  const renderRecommendedFinish = () => {
    if (recommendedFinish) {
      return (
        <>
          <span>Recommended Finish:</span>
          <br />
          {recommendedFinish?.dart1} {recommendedFinish?.dart2}{" "}
          {recommendedFinish?.dart3}
        </>
      );
    } else {
      return null; // Don't render if any value is missing
    }
  };

  const toggleRecommendedFinish = () => {
    setShowRecommendedFinish(!showRecommendedFinish); // Show recommended finish
  };

	const handleExitClick = () => {
    const userConfirmed = window.confirm("Are you sure you want to exit?");
    if (userConfirmed) {
      // User confirmed, you can handle the exit logic here
      console.log("Exiting...");
      setConfirmExit(true); // or navigate to another page, etc.
    }
  };

  return (
		<>
			<Navbar expand="lg" sticky="top">
				<Container>
					<Navbar.Brand href={confirmExit ? "/" : "#"} onClick={handleExitClick} className="hamburger-icon">
						<span></span>
						<span></span>
						<span></span>
					</Navbar.Brand>
				</Container>
			</Navbar>
			<Container fluid className="app-container finishes-game">
				<Row className="Scores">
					<Col 
						xs={12}
						className={`finish-display ${
							finish !== "" ? "finish-selected" : "finish-empty"
						}`}
					>
						{finish !== "" ? finish : "Select a finish to start training!"}
					</Col>
					<Col xs={12} className="recommended-finish">
						{showRecommendedFinish && renderRecommendedFinish()}
					</Col>
				</Row>

				<Row>
					<Col>
						<Button
							className={`ms-1 me-1 mb-2 ${finishType === 'three' ? 'selected' : ''}`}
							onClick={() =>
								generateFinish(threeDartFinishes, isValidFinish, "three")
							}
						>
							Generate 3 Dart Finish
						</Button>
						<Button
							className={`ms-1 me-1 mb-2 ${finishType === 'two' ? 'selected' : ''}`}
							onClick={() =>
								generateFinish(twoDartFinishes, isValidFinish, "two")
							}
						>
							Generate 2 Dart Finish
						</Button>
						<Button
							className={`ms-1 me-1 mb-2 ${finishType === 'one' ? 'selected' : ''}`}
							onClick={() =>
								generateFinish(singleDartFinishes, isValidFinish, "one")
							}
						>
							Generate 1 Dart Finish
						</Button>
						<div className="toggle-switch mt-3 mb-5">
							<p>
								Display recommended finish
							</p>
							<input onChange={toggleRecommendedFinish} type="checkbox" id="switch" /><label for="switch">Toggle</label>
						</div>
					</Col>
				</Row>
			</Container>
		</>
  );
}

export default DartFinishGenerator;
