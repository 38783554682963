import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Navbar from "react-bootstrap/Navbar";
import Table from "react-bootstrap/Table";
import dartIcon from "../assets/dart-icon.png";
import Popup from "./Popup";
import "../App.css";

function DartGame27() {
  const [currentDouble, setCurrentDouble] = useState(1);
  const [score, setScore] = useState(27);
  const [dartSelections, setDartSelections] = useState([null, null, null]);
  const [currentDoubleScore, setCurrentDoubleScore] = useState(0);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [savedHighScore, setSavedHighScore] = useState(0);
  const [numberOfDoubles] = useState(20);
  const [bullseyeScore] = useState(50);
  const [scoreboard, setScoreboard] = useState(
    Array.from({ length: numberOfDoubles + 1 }, (_, i) => ({
      number: i + 1, // 1 to 20 and 21 for Bullseye
      hits: [null, null, null], // Three darts per number, null initially
      score: 0, // Score for each number
    }))
  );
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const [confirmExit, setConfirmExit] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [popupContent, setPopupContent] = useState("");

  useEffect(() => {
    // Calculate and update the current double score
    const hits = scoreboard[currentDouble - 1].hits;
    const hitCount = hits.filter((hit) => hit === true).length;
    const doubleScore =
      currentDouble !== numberOfDoubles + 1
        ? hitCount * currentDouble * 2
        : hitCount * bullseyeScore;
    setCurrentDoubleScore(doubleScore);
  }, [
    dartSelections,
    currentDouble,
    scoreboard,
    numberOfDoubles,
    bullseyeScore,
  ]);

  useEffect(() => {
    setSavedHighScore(localStorage.getItem("dartGame27HighScore") || 0);
  }, []);

  useEffect(() => {
    setSavedHighScore(localStorage.getItem("dartGame27HighScore") || 0);
  }, [gameOver]);

  const handleDart = (dartIndex, isHit) => {
    const newDartSelections = [...dartSelections];
    newDartSelections[dartIndex] = isHit;
    setDartSelections(newDartSelections);

    // Update scoreboard immediately for score calculation
    const newScoreboard = [...scoreboard];
    const currentEntry = newScoreboard[currentDouble - 1];
    currentEntry.hits[dartIndex] = isHit;
    setScoreboard(newScoreboard);
  };

  const handleNextNumber = () => {
    let newScore = score;
    const currentEntry = scoreboard[currentDouble - 1];

    // Calculate score based on hits and misses
    currentEntry.hits.forEach((hit) => {
      if (hit === true) {
        currentDouble !== numberOfDoubles + 1
          ? (newScore += currentDouble * 2) // add double score
          : (newScore += bullseyeScore); // add bull score
      }
    });
    if (currentEntry.hits.every((hit) => hit === false)) {
      currentDouble !== numberOfDoubles + 1
        ? (newScore -= currentDouble * 2) // Subtract Double value on three misses
        : (newScore -= bullseyeScore); // Subtract Bull value on three misses
    }

    // Update the score for the current double
    const newScoreboard = [...scoreboard];
    newScoreboard[currentDouble - 1].score = currentDoubleScore;
    setScoreboard(newScoreboard);
    setScore(newScore);

    // Losing message
    if (newScore <= 0) {
      handleOpenPopup("gameOver", newScore);
      setGameOver(true);
      return;
    }

    // Show congratulatory message when finishing Bullseye
    if (currentDouble === numberOfDoubles + 1) {
      handleOpenPopup("congrats", newScore, newScore > savedHighScore);
      updateHighScore(newScore);
      setGameOver(true);
      return;
    }

    // Reset for next double
    setCurrentDouble(
      currentDouble === numberOfDoubles + 1 ? 1 : currentDouble + 1
    );
    setDartSelections([null, null, null]);
  };

  const updateHighScore = (newScore) => {
    const highScore =
      parseInt(localStorage.getItem("dartGame27HighScore")) || 0;
    if (newScore > highScore) {
      localStorage.setItem("dartGame27HighScore", newScore.toString());
    }
  };

  const resetGame = () => {
    setCurrentDouble(1);
    setScore(27);
    setScoreboard(
      Array.from({ length: numberOfDoubles + 1 }, (_, i) => ({
        number: i + 1,
        hits: [null, null, null],
        score: 0,
      }))
    );
    setDartSelections([null, null, null]);
    setCurrentDoubleScore(0);
    setIsAccordionOpen(false);
    setGameOver(false);
    setShowPopup(false);
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleExitClick = () => {
    const userConfirmed = window.confirm("Are you sure you want to exit?");
    if (userConfirmed) {
      // User confirmed, you can handle the exit logic here
      console.log("Exiting...");
      setConfirmExit(true); // or navigate to another page, etc.
    }
  };

  // Game progress popups
  const handleOpenPopup = (type, gameScore = score, highScore) => {
    setPopupType(type);
    setShowPopup(true);

    let contentHtml = "";
    switch (type) {
      case "confirm":
        contentHtml = "<p>Are you sure you want to start over?</p>";
        break;
      case "gameOver":
        contentHtml = `<p>Game Over</p><p>Your score is ${gameScore}!</p>`;
        break;
      case "congrats":
        contentHtml = `<p>Congratulations!</p><p>You finished the game scoring ${gameScore}!</p>`;
        if (highScore) {
          contentHtml += `<p>New high score!!</p>`;
        }
        break;
      default:
        contentHtml = "<p>Default content</p>";
    }
    setPopupContent(contentHtml);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const confirmAction = () => {
    // Logic for confirm action
    handleClosePopup();
  };

  const cancelAction = (reset) => {
    // Logic for cancel action
    handleClosePopup();
  };

  return (
    <>
      <Navbar expand="lg" sticky="top">
        <Container>
          <Navbar.Brand
            href={confirmExit ? "/" : "#"}
            onClick={handleExitClick}
            className="hamburger-icon"
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Brand>
          <Navbar.Text>
            High Score: <strong>{savedHighScore}</strong>
          </Navbar.Text>
        </Container>
      </Navbar>
      <Container md className="app-container twenty-seven">
        <Row className="game-header justify-content-md-center">
          <Col md={6}>
            <h2>Twenty Seven</h2>
            <Button variant="primary" onClick={handleModalShow}>
              How to play
            </Button>
          </Col>
        </Row>
        <hr></hr>
        <Row className="game-body">
          <Col>
            <Row className="justify-content-md-center">
              <Col md={4}>
                <p>
                  Total: <strong>{score}</strong> | Double Score:{" "}
                  <strong>{currentDoubleScore}</strong> | Double:{" "}
                  <strong>
                    {currentDouble <= numberOfDoubles
                      ? `D${currentDouble}`
                      : "Bull"}
                  </strong>
                </p>
                {[1, 2, 3].map((dartIndex) => (
                  <div className="buttons-container mb-3" key={dartIndex}>
                    <div className="mb-1">
                      {Array.from({ length: dartIndex }).map((_, idx) => (
                        <img
                          key={idx}
                          src={dartIcon}
                          alt={`Dart ${dartIndex}`}
                        />
                      ))}
                    </div>
                    {/* Dart {dartIndex} <br /> */}
                    <Button
                      disabled={gameOver}
                      className={`hit-button ${
                        dartSelections[dartIndex - 1] === true ? "selected" : ""
                      }`}
                      onClick={() => handleDart(dartIndex - 1, true)}
                    >
                      Hit
                    </Button>
                    <Button
                      disabled={gameOver}
                      className={`miss-button ${
                        dartSelections[dartIndex - 1] === false
                          ? "selected"
                          : ""
                      } ms-2`}
                      onClick={() => handleDart(dartIndex - 1, false)}
                    >
                      Miss
                    </Button>
                  </div>
                ))}
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={3}>
                <div className="mt-2">
                  <Button
                    className="w-100"
                    onClick={handleNextNumber}
                    disabled={
                      !dartSelections.every(
                        (selection) => selection !== null
                      ) || gameOver
                    }
                  >
                    Next Number
                  </Button>
                </div>
                <div className="mt-2">
                  {/* Reset Game Button */}
                  <Button
                    className="w-100"
                    onClick={() => handleOpenPopup("confirm")}
                  >
                    Start Again
                  </Button>
                </div>
                <div className="mt-2">
                  <Button className="w-100" onClick={toggleAccordion}>
                    {isAccordionOpen ? "Hide Scores" : "Show Scores"}
                  </Button>
                </div>
              </Col>
            </Row>

            {/* Instruction Modal */}
            <Modal show={modalShow} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>How to play</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Starting with 27 points you get the value of every double hit
                added to your score, 3 misses results in the value of that
                double deducted from your score. Reach zero....game over!!!
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {showPopup && (
              <Popup
                type={popupType}
                content={popupContent}
                confirmAction={confirmAction}
                cancelAction={cancelAction}
                resetGame={resetGame}
              />
            )}

            <Row className="mt-md-5">
              <Col md={12}>
                <div
                  className="accordion-content"
                  style={{ maxHeight: isAccordionOpen ? "1000px" : "0" }}
                >
                  <Table responsive variant="dark">
                    <thead>
                      <tr>
                        <th>Number</th>
                        <th>Score</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scoreboard.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.number <= numberOfDoubles
                              ? `D${item.number}`
                              : "Bullseye"}
                          </td>
                          <td>{item.score}</td>
                          <td>
                            {item.hits
                              .map((hit, idx) =>
                                hit === null ? "-" : hit ? "Hit" : "Miss"
                              )
                              .join(", ")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>TOTAL</td>
                        <td>{score}</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DartGame27;
