import React from 'react';
import Button from 'react-bootstrap/Button';
import "../App.css";

function Popup({ type, content, confirmAction, cancelAction, resetGame }) {
  const handleClose = () => {
    if (cancelAction) cancelAction();
  };
console.log('popup', type, content)
  const renderContent = () => {
    switch (type) {
      case 'confirm':
        console.log('popup2')
        return (
          <div className="confirmation-modal">
            <div className="confirmation-content">
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <Button onClick={resetGame}>Yes, Start Over</Button>
              <Button onClick={handleClose}>No, Cancel</Button>
            </div>
          </div>
        );
      case 'gameOver':
        return (
          <div className="game-over-modal">
            <div className="game-over-content">
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <Button onClick={resetGame}>Start Again</Button>
              <Button onClick={handleClose}>Close</Button>
            </div>
          </div>
        );
      case 'congrats':
        return (
          <div className="congrats-modal">
            <div className="congrats-content">
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <Button onClick={handleClose}>Close</Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
}

export default Popup;
